import { StorageKeyTypes, TEST_KEY } from './modules/storageKeyTypes';
import { StorageType } from './modules/storageTypes';

export function getStorage(type: StorageType): Storage | null {
  let storage: Storage | null = null;

  if (type === '@local') {
    try {
      localStorage.setItem(TEST_KEY, '{}');
      storage = localStorage;
    } catch {
      storage = window.localStorage;
    }
  } else {
    try {
      sessionStorage.setItem(TEST_KEY, '{}');
      storage = sessionStorage;
    } catch {
      storage = window.sessionStorage;
    }
  }

  return storage;
}

export function setItemFromStorage(
  storage: Storage,
  key: StorageKeyTypes,
  item: object,
) {
  storage.setItem(key, JSON.stringify(item));
}

export function getItemFromStorage<T>(
  storage: Storage,
  key: StorageKeyTypes,
): T | null {
  const res = storage.getItem(key) as string | null;

  return res ? (JSON.parse(res) as T) : (res as null);
}

export function clearItemFromStorage(storage: Storage, key: StorageKeyTypes) {
  setItemFromStorage(storage, key, null);
}
