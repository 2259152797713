import { MarkerType } from '@typedef/components/common/KakaoMap/kakaomap.types';
import React, { useCallback, useState } from 'react';
import KakaoMap, { Pos } from '../KakaoMap';
import useMap from '@hooks/store/useMap';
import { usePosition } from '@hooks/store/usePosition';

type Props = {
  markerList?: MarkerType[];
  selectedMarkerIndex: number;
  initialPos: Pos;
  onMarkerClicked: (index: number) => void;
};

const KakaoMapContainer = ({
  markerList = [],
  initialPos,
  selectedMarkerIndex,
  onMarkerClicked,
}: Props) => {
  const { getPosition, setPosition } = usePosition();
  const [level, setLevel] = useState<number>(getPosition().zoom);

  const { __updateMapBounds } = useMap();

  const onUpLevelBtnClicked = useCallback(() => {
    setLevel(Math.max(level - 1, 1));
  }, [level]);

  const onZoomChanged = useCallback((map: kakao.maps.Map) => {
    setLevel(map.getLevel());
  }, []);

  const onBoundsChanged = useCallback((map: kakao.maps.Map) => {
    __updateMapBounds(map.getBounds());
  }, []);

  const onDownLevelBtnClicked = useCallback(() => {
    setLevel(Math.min(level + 1, 14));
  }, [level]);

  const onPositionChanged = useCallback((map: kakao.maps.Map) => {
    setPosition({
      pos: { lat: map.getCenter().getLat(), lng: map.getCenter().getLng() },
      zoom: map.getLevel(),
    });
  }, []);

  return (
    <KakaoMap
      level={level}
      initialPos={initialPos}
      markerList={markerList}
      selectedMarkerIndex={selectedMarkerIndex}
      onUpLevelBtnClicked={onUpLevelBtnClicked}
      onDownLevelBtnClicked={onDownLevelBtnClicked}
      onZoomChanged={onZoomChanged}
      onMarkerClicked={onMarkerClicked}
      onBoundsChanged={onBoundsChanged}
      onPositionChanged={onPositionChanged}
    />
  );
};

export default KakaoMapContainer;
