import { RootRouteTypes } from '@typedef/routes/root.route.types';
import { useCallback } from 'react';

const ROOT_ROUTE_KEY = '@rootroute';

export default function useRootRoute() {
  const getRootRoute = useCallback(
    () => sessionStorage.getItem(ROOT_ROUTE_KEY) as RootRouteTypes | null,
    [],
  );

  const setRootRoute = useCallback(
    (root: RootRouteTypes) => sessionStorage.setItem(ROOT_ROUTE_KEY, root),
    [],
  );

  const clearRootRoute = useCallback(
    () => sessionStorage.removeItem(ROOT_ROUTE_KEY),
    [],
  );

  return {
    getRootRoute,
    setRootRoute,
    clearRootRoute,
  };
}
