const images = {
  blackMarker: '/assets/images/kakaomap/ic_marker_black.png',
  orangeMarker: '/assets/images/kakaomap/ic_marker_orange.png',
  greenMarker: '/assets/images/kakaomap/ic_marker_green.png',
  redMarker: '/assets/images/kakaomap/ic_marker_red.png',
  minus: '/assets/images/kakaomap/ic_minus.png',
  plus: '/assets/images/kakaomap/ic_plus.png',
};

export default images;
