import { UpdateLoginActionDiffTypes } from '@typedef/store/login.types';
import { UPDATE_LOGIN_ACTION_TYPE } from './modules/actionTypes';

export const updateLoginAction = (
  diff: UpdateLoginActionDiffTypes,
  autoLogin: boolean = false,
) => ({
  type: UPDATE_LOGIN_ACTION_TYPE,
  payload: { info: diff, autoLogin },
});

export type LoginActionTypes = ReturnType<typeof updateLoginAction>;
