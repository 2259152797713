import { RootState } from '@store/rootReducer';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateMapBoundsAction } from '@store/map/actions';

export default function useMap() {
  const bounds = useSelector((root: RootState) => root.mapReducer.bounds);

  const dispatch = useDispatch();

  const __updateMapBounds = useCallback(
    (diff: any) => dispatch(updateMapBoundsAction(diff)),
    [dispatch],
  );

  return {
    bounds,
    __updateMapBounds,
  };
}
