import { CamListItemTypes } from '@typedef/components/List/list.types';

export const isExistCamWithinBounds = (
  bounds: kakao.maps.LatLngBounds,
  cam: CamListItemTypes,
) => {
  const latitude = parseFloat(cam.latitude);
  const longitude = parseFloat(cam.longitude);

  const neLatLng = bounds.getNorthEast();
  const swLatLng = bounds.getSouthWest();

  return (
    neLatLng.getLat() > latitude &&
    neLatLng.getLng() > longitude &&
    swLatLng.getLat() < latitude &&
    swLatLng.getLng() < longitude
  );
};
