import React from 'react';
import '@styles/components/List/List.css';
import images from './assets/images';
import {
  CamDetailItemTypes,
  CamListItemTypes,
} from '@typedef/components/List/list.types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { formatTime } from '@libs/factory';

type Props = {
  date: string;
  setDate: React.Dispatch<React.SetStateAction<string>>;
  camDetailList: CamDetailItemTypes[];
  setCamDetailList: React.Dispatch<React.SetStateAction<CamDetailItemTypes[]>>;
  onImageClicked: (item: CamDetailItemTypes) => void;
  fetchMoreData: () => void;
  hasMore: boolean;
  detailImage: string;
  name: string;
  location: string;
  isLoadStatus: (item: CamListItemTypes | CamDetailItemTypes) => string;
};

const List = ({
  date,
  setDate,
  camDetailList,
  setCamDetailList,
  onImageClicked,
  fetchMoreData,
  hasMore,
  detailImage,
  name,
  location,
  isLoadStatus,
}: Props) => {
  return (
    <div className='content-root list'>
      <main className='listMain'>
        <div className='data-container'>
          <div className='ice-data'>
            <img
              className='notice-image'
              src={
                name === '노면 젖음'
                  ? images.notice.wet
                  : name === '결빙주의'
                  ? images.notice.frost
                  : images.notice.normal
              }
              alt=''
            />
            <p className='ice-data-detail'>
              <span
                className={`ice-data-title ${
                  name === '노면 젖음'
                    ? 'wet'
                    : name === '결빙주의'
                    ? 'frost'
                    : 'normal'
                }`}>
                {name}
              </span>{' '}
              : {location}
            </p>
          </div>
          <div className='calendar'>
            <div className='calendar-input'>
              <label className='inputBox-label'>
                <input
                  className='inputBox'
                  type='date'
                  onChange={(e) => setDate(e.target.value)}
                  value={date}
                />
                <p className='choicedDate'>
                  {date.slice(0, 4) +
                    '년 ' +
                    date.slice(5, 7).replace(/(^0+)/, '') +
                    '월 ' +
                    date.slice(8, 10).replace(/(^0+)/, '') +
                    '일'}
                </p>
                <img className='imageDate' src={images.date} alt='' />
              </label>
            </div>
          </div>
        </div>
        <div className='detail-container'>
          <div className='detailImage'>
            {detailImage ? (
              <img
                className='image-fill'
                crossOrigin='anonymous'
                src={detailImage === '' ? images.imageEmpty : detailImage}
                alt=''
              />
            ) : (
              <img
                className='imageEmpty'
                crossOrigin='anonymous'
                src={images.imageEmpty}
                alt=''
              />
            )}
          </div>
          <div className='table-container'>
            <div className='header-section'>
              <div className='item time'>
                <p className='header1'>{'발생 시각'}</p>
              </div>
              <div className='item data'>
                <p className='header2'>{'도로 상태'}</p>
              </div>
            </div>
            <div className='body-section'>
              <div id='scrollablediv'>
                <InfiniteScroll
                  dataLength={camDetailList.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={<h4 className='loding-message'>Loading...</h4>}
                  scrollableTarget='scrollablediv'>
                  {camDetailList.map((item, idx) => (
                    <div
                      className='row'
                      key={idx}
                      onClick={() => onImageClicked(item)}>
                      <div className='item time'>
                        <p className='time detail'>
                          {formatTime(item.captureAt)}
                        </p>
                      </div>
                      <div className='item data'>
                        <img
                          className='notice-image'
                          src={
                            item.isFrost
                              ? images.notice.frost
                              : item.isWetRoads
                              ? images.notice.wet
                              : images.notice.normal
                          }
                          alt=''
                        />
                        <p
                          className={`item detail ${
                            item.isFrost
                              ? 'frost'
                              : item.isWetRoads
                              ? 'wet'
                              : 'normal'
                          }`}>
                          {isLoadStatus(item)}
                        </p>
                        <img
                          className='image-image'
                          src={images.image}
                          alt=''
                        />
                      </div>
                    </div>
                  ))}
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default List;
