import { CamListItemTypes } from '@typedef/components/List/list.types';
import { CamActionTypes } from './actions';
import {
  UPDATE_CAM_ACTION_TYPE,
  UPDATE_SELECTED_INDEX_ACTION_TYPE,
} from './modules/actionTypes';

type CamStateType = {
  camList: CamListItemTypes[];
  selectedCamIndex: number;
  displayedCamIndex: number;
};

const initialState: CamStateType = {
  camList: [],
  selectedCamIndex: -1,
  displayedCamIndex: -1,
};

const camReducer = (
  prev: CamStateType = initialState,
  { type, payload }: CamActionTypes,
): CamStateType => {
  switch (type) {
    case UPDATE_CAM_ACTION_TYPE:
      return { ...prev, camList: payload };
    case UPDATE_SELECTED_INDEX_ACTION_TYPE:
      return { ...prev, selectedCamIndex: payload };
    default:
      return prev;
  }
};

export default camReducer;
