import LoginContainer from '@components/Login/containers/LoginContainer';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

const LoginNavigation = () => {
  return (
    <Routes>
      <Route path='/login' element={<LoginContainer />} />
      <Route path='*' element={<Navigate to='/login' />} />
    </Routes>
  );
};

export default LoginNavigation;
