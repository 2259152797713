import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import loginReducer from './login/reducer';
import camReducer from './cam/reducer';
import mapReducer from '@store/map/reducer';
import secondReducer from './second/reducer';

const persistConfig = {
  key: 'root',
  // localStorage에 저장합니다.
  storage,
  // auth, board, studio 3개의 reducer 중에 auth reducer만 localstorage에 저장합니다.
  whitelist: ['camReducer'],
  // blacklist -> 그것만 제외합니다
};

const rootReducer = combineReducers({
  loginReducer,
  camReducer,
  mapReducer,
  secondReducer,
});

// export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
export default persistReducer(persistConfig, rootReducer);
