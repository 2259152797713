import React, { useCallback } from 'react';
import GNB from '../GNB';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  GNBItemNameTypes,
  GNBItemTypes,
} from '@typedef/components/common/GNB/gnb.types';
import images from 'assets/images';
import mainNavigationEndpoints from '@routes/types/MainNavigationEndpoints';

const GNBContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const onLogoClicked = useCallback(() => {
    const page: GNBItemNameTypes = 'home';

    navigate(page);
  }, [navigate]);

  const onBackBtnClicked = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onHomeBtnClicked = useCallback(() => {
    navigate(mainNavigationEndpoints.home);
  }, [navigate]);

  const onSettingBtnClicked = useCallback(() => {
    navigate(mainNavigationEndpoints.setting);
  }, [navigate]);

  const data: GNBItemTypes[] = [
    {
      name: 'back',
      onClicked: onBackBtnClicked,
      icon: images.gnb.back,
    },
    {
      name: 'home',
      onClicked: onHomeBtnClicked,
      icon: images.gnb.home,
    },
  ];

  if (location.pathname !== '/setting') {
    data.push({
      name: 'setting',
      onClicked: onSettingBtnClicked,
      icon: images.gnb.setting,
    });
  }

  return <GNB onLogoClicked={onLogoClicked} data={data} />;
};

export default GNBContainer;
