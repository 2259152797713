import React, { RefObject } from 'react';
import '@styles/components/Setting/setting.styles.css';
import '@styles/components/Setting/newstime.setting.styles.css';
import images from './assets/images';

interface Props {
  second: number;
  inputMode: boolean;
  inputRef: RefObject<HTMLInputElement | null>;
  onTimeSettingChange: (time: number) => void;
  increaseSecond: () => void;
  decreaseSecond: () => void;
  onSaveButtonClicked: () => void;
  onCancelButtonClicked: () => void;
  onTimeSettingClicked: () => void;
  onBlur: () => void;
}
const Setting = ({
  second,
  inputMode,
  inputRef,
  onTimeSettingChange,
  increaseSecond,
  decreaseSecond,
  onSaveButtonClicked,
  onCancelButtonClicked,
  onTimeSettingClicked,
  onBlur,
}: Props) => {
  return (
    <div className='setting'>
      <div className='container'>
        <div className='title'>페이지 설정</div>
        <div className='news-time-setting-root'>
          <label className='news-time-title'>뉴스라인 시간 설정</label>
          <div className='time-input-box'>
            <div className='input-container' onClick={onTimeSettingClicked}>
              <input
                className='time-input-input'
                type='text'
                value={inputMode ? second : `${second}초`}
                onChange={(e) => onTimeSettingChange(parseInt(e.target.value))}
                ref={inputRef}
                onBlur={onBlur}
              />
            </div>
            <div className='time-input-button'>
              <button className='up-btn' type='button' onClick={increaseSecond}>
                <img src={images.upBtn} alt='up' />
              </button>
              <button
                className='down-btn'
                type='button'
                onClick={decreaseSecond}>
                <img src={images.downBtn} alt='down' />
              </button>
            </div>
          </div>
        </div>

        <div className='btn-container'>
          <button
            className='cancel'
            type='button'
            onClick={onCancelButtonClicked}>
            취소
          </button>
          <button className='save' type='button' onClick={onSaveButtonClicked}>
            저장
          </button>
        </div>
      </div>
    </div>
  );
};

export default Setting;
