import { UpdateLoginActionDiffTypes } from '@typedef/store/login.types';
import axios from 'axios';
import {
  clearItemFromStorage,
  getItemFromStorage,
  getStorage,
  setItemFromStorage,
} from './storage/storage';
import { BasicApiResponse } from '@libs/baseApi';

export const API_ORIGIN = 'https://api.birdai.kr';

axios.defaults.baseURL = API_ORIGIN;

const REFRESH_STATUS = 1000;

axios.interceptors.response.use(
  (res) => res,
  async (e) => {
    const status = e?.response?.status;
    if (status === 401) {
      const { refreshToken } = getItemFromStorage<UpdateLoginActionDiffTypes>(
        getStorage('@session'),
        '@login',
      );

      const {
        data,
        config: { status },
      } = await requestSecurePost<{
        accessToken: string;
        refreshToken: string;
      }>(apiRoute.auth.refresh, {}, {}, refreshToken);

      if (status === 200) {
        setItemFromStorage(getStorage('@session'), '@login', data);
        return {
          ...e,
          status: 1000,
          data: { accessToken: data.accessToken },
        };
      }

      if (status === 401) {
        clearItemFromStorage(getStorage('@session'), '@login');
      }
    }
  },
);

export const apiRoute = {
  auth: {
    login: '/api/v1/login-user',
    logout: '/api/v1/logout',
    refresh: '/api/v1/refresh',
  },
  user: {
    signup: '/api/v1/users',
  },
  cam: {
    news: '/api/v1/cams/news',
    list: '/api/v1/cams/list',
    detail: '/api/v1/cams/detail',
    file: '/api/v1/cams/file',
  },
};

export type BasicListDataType<T> = {
  rows: T[];
  page: number;
  maxPage: number;
};

export function requestGet<T>(
  url: string,
  header: object,
): Promise<BasicApiResponse<T>> {
  return axios
    .get(url, {
      headers: {
        'Content-Type': 'application/json',
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestSecureGet<T>(
  url: string,
  header: object,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .get(url, {
      headers: {
        //token이 필요한 API요청 시 header Authorizaion에 token담아서 보내기
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ...header,
      },
    })
    .then((res) => {
      if (res.status === REFRESH_STATUS) {
        return axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${res.data.accessToken}`,
            ...header,
          },
        });
      } else {
        return res;
      }
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestDelete<T>(
  url: string,
  header: object,
): Promise<BasicApiResponse<T>> {
  return axios
    .delete(url, {
      headers: {
        'Content-Type': 'application/json',
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestSecureDelete<T>(
  url: string,
  header: object,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .delete(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestSecurePost<T>(
  url: string,
  header: object,
  body: object,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .post(url, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestPost<T>(
  url: string,
  header: object,
  body: object,
): Promise<BasicApiResponse<T>> {
  return axios
    .post(url, body, {
      headers: {
        'Content-Type': 'application/json',
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestSecurePatch<T>(
  url: string,
  header: object,
  body: object,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .patch(url, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestMultipartPost<T>(
  url: string,
  header: object,
  body: FormData,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .post(url, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestMultipartPatch<T>(
  url: string,
  header: object,
  body: FormData,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .patch(url, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}
