import KakaoMapContainer from '@components/common/KakaoMap/containers/KakaoMapContainer';
import React from 'react';
import { Pos } from '@components/common/KakaoMap/KakaoMap';
import { MarkerType } from '@typedef/components/common/KakaoMap/kakaomap.types';

type Props = {
  initialPos: Pos;
  markerList: MarkerType[];
  selectedMarkerIndex: number;
  onMarkerClicked: (index: number) => void;
};

const Home = ({
  initialPos,
  markerList,
  selectedMarkerIndex,
  onMarkerClicked,
}: Props) => {
  return (
    <KakaoMapContainer
      markerList={markerList}
      initialPos={initialPos}
      selectedMarkerIndex={selectedMarkerIndex}
      onMarkerClicked={onMarkerClicked}
    />
  );
};

export default Home;
