export const formatTime = (date: string) => {
  const hour = Number(date.slice(11, 13));
  const minute = Number(date.slice(14, 16));
  let time = '';

  if (hour < 13) {
    time = '오전 ' + hour + '시 ';
  } else {
    time = '오후 ' + (hour - 12) + '시 ';
  }
  console.log('시간: ', time);

  return (time += minute + '분');
};

export const formatDate = (date: Date, format: string) => {
  const result = format.toString();

  result.replace('yyyy', date.getFullYear().toString());
  result.replace('MM', date.getMonth().toString());
  result.replace('dd', date.getDate().toString());

  return result;
};
