import RootNavigationContainer from '@routes/containers/RootNavigationContainer';
import rootReducer from '@store/rootReducer';
import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import '../styles/core.css';

const store = createStore(
  rootReducer,
  process.env.NODE_ENV === 'development' ? composeWithDevTools() : undefined,
);

const persistor = persistStore(store);

store.dispatch({
  type: '@@INIT',
  paload: {},
});

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RootNavigationContainer />
      </PersistGate>
    </Provider>
  );
};

export default App;
