import { MapActionTypes } from './actions';
import { UPDATE_MAP_BOUNDS_ACTION } from '@store/map/modules/actionTypes';

type MapStateType = {
  bounds: kakao.maps.LatLngBounds;
};

const initialState: MapStateType = {
  bounds: null,
};

const mapReducer = (
  prev: MapStateType = initialState,
  { type, payload }: MapActionTypes,
): MapStateType => {
  switch (type) {
    case UPDATE_MAP_BOUNDS_ACTION:
      return { ...payload, bounds: payload };
    default:
      return prev;
  }
};

export default mapReducer;
