import { RootState } from '@store/rootReducer';
import { updateSecondAction } from '@store/second/actions';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function useSecond() {
  const secondObj = useSelector((root: RootState) => root.secondReducer);

  const dispatch = useDispatch();

  const __updateSecondFromHooks = useCallback(
    (diff: number) => dispatch(updateSecondAction(diff)),
    [dispatch],
  );

  return {
    secondObj,
    __updateSecondFromHooks,
  };
}
