import useCam from '@hooks/store/useCam';
import useLogin from '@hooks/store/useLogin';
import { alertMessage } from '@libs/alertMessage';
import {
  apiRoute,
  API_ORIGIN,
  BasicListDataType,
  requestSecureGet,
} from '@libs/api';
import {
  CamDetailItemTypes,
  CamListItemTypes,
} from '@typedef/components/List/list.types';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import List from '../List';

// 오늘 날짜 구하기
let today = new Date();

let year = today.getFullYear();
let month = ('0' + (today.getMonth() + 1)).slice(-2);
let day = ('0' + today.getDate()).slice(-2);

let dateString = year + '-' + month + '-' + day;

const itemCnt = 20;

const ListContainer = () => {
  const { id } = useParams();
  const item = useLocation();

  const { loginInfo } = useLogin();
  const [date, setDate] = useState(dateString);
  const [camDetailList, setCamDetailList] = useState<CamDetailItemTypes[]>([]);
  const [maxPage, setMaxPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [detailImage, setDetailImage] = useState('');
  const [name, setName] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const data = item.state as CamListItemTypes;

  const loadCamDetailList = useCallback(
    async (page: number) => {
      if (page > maxPage) {
        setHasMore(false);
        return;
      }

      const {
        data,
        config: { status },
      } = await requestSecureGet<BasicListDataType<CamDetailItemTypes>>(
        apiRoute.cam.detail +
          `/${id}?page=${page}&perPage=${itemCnt}&logDate=${date}`,
        {},
        loginInfo.accessToken,
      );

      if (status !== 200) {
        alertMessage('목록을 불러오지 못했습니다.');
        return;
      }

      if (data.rows.length === 0) {
        setHasMore(false);
        setName('정상');
        setDetailImage('');
      } else {
        setDetailImage(
          API_ORIGIN + apiRoute.cam.file + `/${data.rows[0].fileName}`,
        );
        setName(isLoadStatus(data.rows[0]));
      }

      if (page === 0) {
        setMaxPage(data.maxPage);
        setCamDetailList(data.rows);
        return;
      }
      setCamDetailList([...camDetailList, ...data.rows]);
    },
    [maxPage, id, date, loginInfo.accessToken, camDetailList],
  );

  const isLoadStatus = useCallback(
    (item: CamListItemTypes | CamDetailItemTypes) => {
      if (item.isFrost) {
        return '결빙주의';
      }
      if (item.isWetRoads) {
        return '노면 젖음';
      }
      return '정상';
    },
    [],
  );

  const onImageClicked = useCallback((item: CamDetailItemTypes) => {
    setDetailImage(API_ORIGIN + apiRoute.cam.file + `/${item.fileName}`);
    setName(isLoadStatus(item));
  }, []);

  const changeDate = useCallback(() => {
    setHasMore(true);
    // setDetailImage('');
    loadCamDetailList(0);
    setCurrentPage(0);
  }, [loadCamDetailList]);

  const fetchMoreData = useCallback(() => {
    loadCamDetailList(currentPage + 1);
    setCurrentPage(currentPage + 1);
  }, [currentPage, loadCamDetailList]);

  useEffect(() => {
    loadCamDetailList(0);
    setLocation(data.location);
    setName(isLoadStatus(data));
  }, []);

  useEffect(() => {
    changeDate();
  }, [date]);

  return (
    <List
      date={date}
      setDate={setDate}
      camDetailList={camDetailList}
      setCamDetailList={setCamDetailList}
      onImageClicked={onImageClicked}
      fetchMoreData={fetchMoreData}
      hasMore={hasMore}
      detailImage={detailImage}
      name={name}
      location={location}
      isLoadStatus={isLoadStatus}
    />
  );
};

export default ListContainer;
