import { updateLoginAction } from '@store/login/actions';
import { RootState } from '@store/rootReducer';
import { UpdateLoginActionDiffTypes } from '@typedef/store/login.types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

export default function useLogin() {
  const loginInfo = useSelector(
    (root: RootState) => root.loginReducer.loginInfo,
  );

  const dispatch = useDispatch(); //dispatch변수를 활용하여 액션을 호출 가능

  const __updateLoginFromHooks = useCallback(
    (diff: UpdateLoginActionDiffTypes, autoLogin: boolean = false) =>
      dispatch(updateLoginAction(diff, autoLogin)),
    [dispatch],
  );

  return {
    loginInfo,
    __updateLoginFromHooks,
  };
}
