import { LoadStatusTypes } from '@typedef/components/common/CFooter/cfooter.types';
import images from 'assets/images';

type Props = {
  name: LoadStatusTypes;
  location: string;
};

const NewsLineText = ({ name, location }: Props) => {
  return (
    <div className='text-container'>
      <img
        className={'warning-img'}
        src={
          name === '결빙주의'
            ? images.footer.frost
            : name === '노면 젖음'
            ? images.footer.wet
            : images.footer.normal
        }
        alt={'Footer Warning Icon'}
      />
      <div
        className={`name-text ${
          name === '결빙주의'
            ? 'frost'
            : name === '노면 젖음'
            ? 'wet'
            : 'normal'
        }`}>
        {name}
      </div>
      <div className={'colon-text'}>{':'}</div>
      <div className={'location-text'}>{location}</div>
    </div>
  );
};

export default NewsLineText;
