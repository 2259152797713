export const TEST_KEY = '@test';

export const LOGIN_KEY = '@login';

export const SECOND_KEY = '@second';

export type StorageKeyTypes =
  | typeof LOGIN_KEY
  | typeof TEST_KEY
  | typeof SECOND_KEY;
