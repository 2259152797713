const images = {
  image: '/assets/components/CList/ic-image.png',
  notice: {
    warning: '/assets/images/CList/ic-notice.png',
    frost: '/assets/images/footer/ic-frost.png',
    wet: '/assets/images/footer/ic-wet.png',
    normal: '/assets/images/footer/ic-normal.png',
  },
  imageEmpty: '/assets/components/CList/image-empty.png',
  date: '/assets/components/CList/ic-date.png',
};

export default images;
