import { Pos } from '@components/common/KakaoMap/KakaoMap';

import { RootState } from '@store/rootReducer';
import { PositionType } from '@typedef/components/common/KakaoMap/kakaomap.types';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const POS_KEY = '@pos';

export function usePosition() {
  const getPosition = () => {
    return JSON.parse(
      window.sessionStorage.getItem(POS_KEY) ||
        `{"pos": { "lat": 37.57855034577543, "lng": 126.97701871170517 },"zoom": 3}`,
    ) as PositionType;
  };

  const setPosition = (pos: PositionType) => {
    window.sessionStorage.setItem(POS_KEY, JSON.stringify(pos));
  };

  return {
    getPosition,
    setPosition,
  };
}
