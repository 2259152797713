import React from 'react';
import images from 'assets/images';
import '@styles/common/CFooter/cfooter.styles.css';
import NewsLineText from './components/NewLineText';
import { LoadStatusTypes } from '@typedef/components/common/CFooter/cfooter.types';

type Props = {
  name: LoadStatusTypes;
  location: string;
  temp: string;
};

const CFooter = ({ name, location, temp }: Props) => {
  return (
    <div className={'footer'}>
      <div className={'text-wrapper'}>
        <div className={'news-text'}>{'NEWS'}</div>
        <div className={'bar'} />
        <NewsLineText key={location} name={name} location={location} />
      </div>
      <div className={'temp-wrapper'}>
        <img
          className={'temp-img'}
          src={images.footer.temp}
          alt={'Footer Temp Icon'}
        />
        <div className={'temp-text'}>{temp}</div>
        <div className={'temp-unit-text'}>{'°C'}</div>
      </div>
    </div>
  );
};

export default CFooter;
