import useSecond from '@hooks/store/useSecond';
import { alertMessage } from '@libs/alertMessage';
import { getItemFromStorage, getStorage } from '@libs/storage/storage';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Setting from '../Setting';

const SettingContainer = () => {
  const navigate = useNavigate();
  const { secondObj, __updateSecondFromHooks } = useSecond();
  const [second, setSecond] = useState(secondObj.second);
  const [inputMode, setInputMode] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const increaseSecond = useCallback(() => {
    setSecond(second + 1);
  }, [second]);

  const decreaseSecond = useCallback(() => {
    if (second === 1) {
      setSecond(1);
    } else setSecond(second - 1);
  }, [second]);

  const onCancelButtonClicked = useCallback(() => {
    navigate(-1);
  }, []);

  const onSaveButtonClicked = useCallback(() => {
    if (second == 0) {
      alertMessage('1초부터 입력가능합니다');
      return;
    }
    __updateSecondFromHooks(second);
    alertMessage('저장되었습니다');
    navigate(-1);
  }, [second, __updateSecondFromHooks]);

  const onTimeSettingChange = useCallback((time: number) => {
    setSecond(time || 0);
  }, []);

  const onTimeSettingClicked = useCallback(() => {
    setInputMode(true);
    inputRef.current.focus();
  }, [inputRef]);

  const onBlur = useCallback(() => {
    setInputMode(false);
  }, [inputMode]);

  useEffect(() => {
    setSecond(secondObj.second); //처음에 시간 설정
  }, [secondObj]);

  return (
    <Setting
      second={second}
      increaseSecond={increaseSecond}
      decreaseSecond={decreaseSecond}
      onSaveButtonClicked={onSaveButtonClicked}
      onCancelButtonClicked={onCancelButtonClicked}
      onTimeSettingChange={onTimeSettingChange}
      inputMode={inputMode}
      inputRef={inputRef}
      onTimeSettingClicked={onTimeSettingClicked}
      onBlur={onBlur}
    />
  );
};

export default SettingContainer;
