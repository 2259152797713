import React from 'react';
import images from 'assets/images';
import '@styles/common/GNB/gnb.styles.css';
import { GNBItemTypes } from '@typedef/components/common/GNB/gnb.types';

type Props = {
  onLogoClicked: () => void;
  data: GNBItemTypes[];
};

const GNB = ({ onLogoClicked, data }: Props) => {
  return (
    <header className={'header'}>
      <div className={'logo-wrapper'} onClick={onLogoClicked}>
        <img className={'logo'} src={images.logo} alt={'logo'} />
        <div className={'logo-text'}>{'블랙아이스 탐지 비전 AI 시스템'}</div>
      </div>
      <div className={'nav'}>
        {data.map((element, index) => (
          <div
            key={index}
            className={'nav-img-wrapper'}
            onClick={element.onClicked}>
            <img className={'nav-img'} src={element.icon} alt={element.name} />
          </div>
        ))}
      </div>
    </header>
  );
};

export default GNB;
