import React, { useState, useEffect, useCallback } from 'react';
import Home from '../Home';
import { Pos } from '@components/common/KakaoMap/KakaoMap';
import useCam from '@hooks/store/useCam';
import { requestGet, BasicListDataType, apiRoute } from '@libs/api';
import { CamListItemTypes } from '@typedef/components/List/list.types';
import useLogin from '@hooks/store/useLogin';
import { useLocation, useNavigate } from 'react-router-dom';
import images from '@components/common/KakaoMap/assets/images';
import { usePosition } from '@hooks/store/usePosition';

const HomeContainer = () => {
  const { getPosition } = usePosition();
  const navigate = useNavigate();
  const [defaultPos, setDefaultPos] = useState<Pos>({
    lat: 37.57855034577543,
    lng: 126.97701871170517,
  });
  const [initialPos, setInitialPos] = useState<Pos>(getPosition().pos);
  const {
    loginInfo: { accessToken },
  } = useLogin();
  const {
    camInfo: { camList, selectedCamIndex },
    __updateListFromHooks,
    __updateSelectedIndex,
  } = useCam();

  const onMarkerClicked = useCallback(
    (index: number) => {
      __updateSelectedIndex(index);
      navigate('/list/' + camList[index].id, {
        state: camList[index],
      });
    },
    [__updateSelectedIndex, navigate, camList],
  );

  const updateMarkerList = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestGet<BasicListDataType<CamListItemTypes>>(
      apiRoute.cam.list + `?page=0&perPage=50`,
      { Authorization: `Bearer ${accessToken}` },
    );

    if (status === 200) {
      __updateListFromHooks(data.rows);
      if (data.rows.length > 0) {
        const firstMarker = data.rows[0];

        const pos = getPosition().pos;
        if (pos.lat === defaultPos.lat && pos.lng === defaultPos.lng) {
          setInitialPos({
            lat: parseFloat(firstMarker.latitude),
            lng: parseFloat(firstMarker.longitude),
          });
        }

        __updateSelectedIndex(0);
      } else {
        __updateSelectedIndex(-1);
      }
    }
  }, [accessToken, initialPos]);

  useEffect(() => {
    updateMarkerList();
  }, []);

  return (
    <Home
      initialPos={initialPos}
      markerList={camList.map((cam) => ({
        pos: {
          lat: parseFloat(cam.latitude),
          lng: parseFloat(cam.longitude),
        },
        title: cam.isFrost ? '결빙주의' : cam.isWetRoads ? '노면 젖음' : '정상',
        image: cam.isFrost
          ? images.redMarker
          : cam.isWetRoads
          ? images.orangeMarker
          : images.greenMarker,
      }))}
      selectedMarkerIndex={selectedCamIndex}
      onMarkerClicked={onMarkerClicked}
    />
  );
};

export default HomeContainer;
