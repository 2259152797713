import useLogin from '@hooks/store/useLogin';
import useSecond from '@hooks/store/useSecond';
import { getItemFromStorage, getStorage } from '@libs/storage/storage';
import RootNavigation from '@routes/RootNavigation';
import React, { useEffect } from 'react';

const RootNavigationContainer = () => {
  const { loginInfo } = useLogin();
  const { secondObj, __updateSecondFromHooks } = useSecond();

  useEffect(() => {
    const second = getItemFromStorage<{ second: number }>(
      getStorage('@local'),
      '@second',
    );

    if (second) {
      __updateSecondFromHooks(second.second);
    }
  }, []);

  return <RootNavigation root={loginInfo ? 'main' : 'login'} />;
};

export default RootNavigationContainer;
