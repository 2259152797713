import React, { useState, useCallback, useEffect } from 'react';
import CFooter from '../CFooter';
import useCam from '@hooks/store/useCam';
import useMap from '@hooks/store/useMap';
import { isExistCamWithinBounds } from '@libs/checker';
import useSecond from '@hooks/store/useSecond';
import { useLocation } from 'react-router-dom';
import { LoadStatusTypes } from '@typedef/components/common/CFooter/cfooter.types';

const CFooterContainer = () => {
  const pathname = useLocation();
  const {
    secondObj: { second },
  } = useSecond();
  const {
    camInfo: { camList, selectedCamIndex },
    __updateSelectedIndex,
  } = useCam();
  const [count, setCount] = useState<number>(0);
  const [name, setName] = useState<LoadStatusTypes>('');
  const [location, setLocation] = useState<string>('');
  const [temp, setTemp] = useState<string>('- -');

  const { bounds } = useMap();

  const updateNews = useCallback(async () => {
    if (!bounds && pathname.pathname === '/home') {
      return;
    }

    let newCamIndex: number = -1;

    if (bounds) {
      for (
        let i =
          selectedCamIndex + 1 === camList.length ? 0 : selectedCamIndex + 1;
        i !== selectedCamIndex;
        i = camList.length - 1 > i ? i + 1 : 0
      ) {
        if (isExistCamWithinBounds(bounds, camList[i])) {
          newCamIndex = i;
          break;
        }
      }
    } else {
      newCamIndex =
        selectedCamIndex + 1 === camList.length ? 0 : selectedCamIndex + 1;
    }

    if (newCamIndex < 0) {
      if (camList[selectedCamIndex].isFrost) {
        setName('결빙주의');
      } else if (camList[selectedCamIndex].isWetRoads) {
        setName('노면 젖음');
      } else {
        setName('정상');
      }
      setTemp('- -');
      setLocation(camList[selectedCamIndex].location);
      return;
    }

    __updateSelectedIndex(newCamIndex);

    const selectedCam = camList[newCamIndex];

    if (selectedCam.isFrost) {
      setName('결빙주의');
    } else if (selectedCam.isWetRoads) {
      setName('노면 젖음');
    } else {
      setName('정상');
    }

    setTemp('- -');
    setLocation(selectedCam.location);
  }, [bounds, selectedCamIndex, __updateSelectedIndex, camList]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCount((prev) => prev + 1);
    }, second * 1000);

    return () => clearInterval(timer);
  }, [second]);

  useEffect(() => {
    updateNews();
  }, [count]);

  useEffect(() => {
    updateNews();
  }, [camList]);

  return <CFooter name={name} location={location} temp={temp} />;
};

export default CFooterContainer;
