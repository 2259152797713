import { CamListItemTypes } from '@typedef/components/List/list.types';
import {
  UPDATE_CAM_ACTION_TYPE,
  UPDATE_SELECTED_INDEX_ACTION_TYPE,
} from './modules/actionTypes';

export const updateCamAction = (diff: CamListItemTypes[]) => ({
  type: UPDATE_CAM_ACTION_TYPE,
  payload: diff,
});

export const updateSelectedIndexAction = (diff: number) => ({
  type: UPDATE_SELECTED_INDEX_ACTION_TYPE,
  payload: diff,
});

export type CamActionTypes =
  | ReturnType<typeof updateCamAction>
  | ReturnType<typeof updateSelectedIndexAction>;
