import { updateCamAction, updateSelectedIndexAction } from '@store/cam/actions';
import { RootState } from '@store/rootReducer';
import { CamListItemTypes } from '@typedef/components/List/list.types';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function useCam() {
  const camInfo = useSelector((root: RootState) => root.camReducer);

  const dispatch = useDispatch();

  const __updateListFromHooks = useCallback(
    (diff: CamListItemTypes[]) => dispatch(updateCamAction(diff)),
    [dispatch],
  );

  const __updateSelectedIndex = useCallback(
    (diff: number) => dispatch(updateSelectedIndexAction(diff)),
    [dispatch],
  );

  return {
    camInfo,
    __updateListFromHooks,
    __updateSelectedIndex,
  };
}
