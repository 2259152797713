const images = {
  logo: '/assets/images/logo.png',
  dateInput: {
    date: '/assets/images/dateInput/ic_date.png',
  },
  gnb: {
    home: '/assets/images/gnb/ic_home.png',
    setting: '/assets/images/gnb/ic_setting.png',
    back: '/assets/images/gnb/ic_back.png',
  },
  footer: {
    temp: '/assets/images/footer/ic_temp.png',
    warning: '/assets/images/footer/ic_warning.png',
    wet: '/assets/images/footer/ic-wet.png',
    frost: '/assets/images/footer/ic-frost.png',
    normal: '/assets/images/footer/ic-normal.png',
  },
  input: {
    delete: '/assets/images/input/ic_delete.png',
    eye: '/assets/images/input/ic_eye.png',
    eyeDisable: '/assets/images/input/ic_eye_disable.png',
  },
} as const;

export default images;
