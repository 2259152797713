import useLogin from '@hooks/store/useLogin';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Login from '../Login';
import { alertMessage } from '@libs/alertMessage';
import { apiRoute, requestPost } from '@libs/api';
import { stringify } from 'querystring';
import useRootRoute from '@hooks/routes/useRootRoute';
import { checkServerIdentity } from 'tls';

const LoginContainer = () => {
  //const { setAccessToken, setRefreshToken } = useToken();
  const { setRootRoute } = useRootRoute();
  // const navigate = useNavigate();

  const { __updateLoginFromHooks } = useLogin();

  const [id, setId] = useState('');
  const [pw, setPw] = useState('');
  const [checked, setChecked] = useState(true);

  const handleChecked = useCallback(() => {
    setChecked(!checked);
  }, [checked]);

  const onIdChanged = useCallback((value: string) => {
    setId(value);
  }, []);

  const onPwChanged = useCallback((value: string) => {
    setPw(value);
  }, []);

  const onLoginClicked = useCallback(async () => {
    const {
      config: { status },
      data,
    } = await requestPost<{
      accessToken: string;
      refreshToken: string;
    }>(
      apiRoute.auth.login,
      {},
      {
        userName: id,
        password: pw,
      },
    );

    if (status === 200) {
      __updateLoginFromHooks(data, checked);
    } else {
      alertMessage('로그인에 실패했습니다');
    }
  }, [id, pw, checked, __updateLoginFromHooks]);

  return (
    <Login
      checked={checked}
      id={id}
      pw={pw}
      onIdChanged={onIdChanged}
      onPwChanged={onPwChanged}
      onLoginClicked={onLoginClicked}
      handleChecked={handleChecked}
    />
  );
};

export default LoginContainer;
