import React, { Ref } from 'react';
import '@styles/common/KakaoMap/kakaomap.styles.css';
import { Map, CustomOverlayMap, MapMarker } from 'react-kakao-maps-sdk';
import { MarkerType } from '@typedef/components/common/KakaoMap/kakaomap.types';
import images from '@components/common/KakaoMap/assets/images';

export type Pos = {
  lat: number;
  lng: number;
};

type Props = {
  initialPos: Pos;
  markerList: MarkerType[];
  mapRef?: Ref<kakao.maps.Map>;
  level: number;
  selectedMarkerIndex: number;
  onUpLevelBtnClicked: () => void;
  onDownLevelBtnClicked: () => void;
  onZoomChanged: (map: kakao.maps.Map) => void;
  onMarkerClicked: (index: number) => void;
  onBoundsChanged: (map: kakao.maps.Map) => void;
  onPositionChanged: (map: kakao.maps.Map) => void;
};

const KakaoMap = ({
  initialPos,
  markerList,
  mapRef,
  level,
  selectedMarkerIndex,
  onUpLevelBtnClicked,
  onDownLevelBtnClicked,
  onZoomChanged,
  onMarkerClicked,
  onBoundsChanged,
  onPositionChanged,
}: Props) => {
  return (
    <article className='map-root'>
      <section className='map-section'>
        <Map
          ref={mapRef}
          center={initialPos}
          style={{ height: '100%', width: '100%' }}
          level={level}
          onZoomChanged={onZoomChanged}
          onBoundsChanged={onBoundsChanged}
          onTileLoaded={onPositionChanged}>
          {markerList.map((marker, index) => (
            <CustomOverlayMap key={index} position={marker.pos} yAnchor={1.9}>
              <button
                className={`marker-label ${
                  selectedMarkerIndex === index ? 'active' : 'inactive'
                }`}
                onClick={() => onMarkerClicked(index)}>
                {marker.title}
              </button>
            </CustomOverlayMap>
          ))}
          {markerList.map((marker, index) => (
            <MapMarker
              key={index}
              position={marker.pos}
              onClick={() => onMarkerClicked(index)}
              image={{
                src: marker.image ?? images.greenMarker,
                size: {
                  width: 24,
                  height: 24,
                },
              }}
            />
          ))}
        </Map>
        <button className='up-level-btn' onClick={onUpLevelBtnClicked}>
          <img src={images.plus} alt='' />
        </button>
        <button className='down-level-btn' onClick={onDownLevelBtnClicked}>
          <img src={images.minus} alt='' />
        </button>
      </section>
    </article>
  );
};

export default KakaoMap;
