import { getStorage, setItemFromStorage } from '@libs/storage/storage';
import { SecondActionTypes } from './actions';
import { UPDATE_SECOND_ACTION_TYPE } from './modules/actionTypes';

export type SecondStateType = {
  second: number;
};

const initialState: SecondStateType = {
  second: 5,
};

const secondReducer = (
  prev = initialState,
  { type, payload }: SecondActionTypes,
): SecondStateType => {
  switch (type) {
    case UPDATE_SECOND_ACTION_TYPE: {
      setItemFromStorage(getStorage('@local'), '@second', payload);

      return payload;
    }
    default:
      return prev;
  }
};

export default secondReducer;
