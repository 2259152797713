import {
  getItemFromStorage,
  getStorage,
  setItemFromStorage,
} from '@libs/storage/storage';
import { InitActionTypes } from '@store/actions';
import { INIT_ACTION_TYPE } from '@store/modules/actonTypes';
import { UpdateLoginActionDiffTypes } from '@typedef/store/login.types';
import { LoginActionTypes } from './actions';
import { UPDATE_LOGIN_ACTION_TYPE } from './modules/actionTypes';

type LoginStateType = {
  loginInfo: UpdateLoginActionDiffTypes | null;
};

const initialState: LoginStateType = {
  loginInfo: null,
};

const loginReducer = (
  prev: LoginStateType = initialState,
  { type, payload }: LoginActionTypes | InitActionTypes,
): LoginStateType => {
  switch (type) {
    case UPDATE_LOGIN_ACTION_TYPE: {
      if (payload.autoLogin) {
        setItemFromStorage(getStorage('@session'), '@login', payload.info);
      }

      return {
        loginInfo: payload.info,
      };
    }

    case INIT_ACTION_TYPE:
      return {
        loginInfo: getItemFromStorage(getStorage('@session'), '@login'),
      };

    default:
      return prev;
  }
};

export default loginReducer;
