import React, { useState } from 'react';
import '@styles/components/Login/login.styles.css';
import images from '../Login/assets/images';
import CheckBox from './components/CheckBox';
import SettingBtn from './components/SettingBtn';
import CInputContainer from '@components/common/CInput/containers/CInputContainer';

type Props = {
  checked: boolean;
  id: string;
  pw: string;
  onIdChanged: (value: string) => void;
  onPwChanged: (value: string) => void;
  onLoginClicked: () => void;
  handleChecked: () => void;
};

const Login = ({
  checked,
  id,
  pw,
  onIdChanged,
  onPwChanged,
  onLoginClicked,
  handleChecked,
}: Props) => {
  return (
    <div className='login-root'>
      <div className='login-top'></div>
      <div className='login-container'>
        <div className='login-logo'>
          <img className='logo-image' src={images.logo} alt='logo' />
        </div>
        <p className='title'>블랙아이스 탐지 비전AI시스템</p>

        <div className='login-form'>
          <form
            className='login-formtag'
            onSubmit={(e) => {
              e.preventDefault();
              onLoginClicked();
            }}>
            <div className='input-container'>
              <label htmlFor='login-id-input' hidden>
                {'ID'}
              </label>
              <input
                type='text'
                id='login-id-input'
                className='login-input-box'
                placeholder='ID'
                value={id}
                onChange={(e) => onIdChanged(e.target.value)}
              />
            </div>
            <div className='input-container'>
              <label htmlFor='login-id-input' hidden>
                {'Password'}
              </label>
              <input
                type='password'
                id='login-pw-input'
                className='login-input-box'
                placeholder='Password'
                value={pw}
                onChange={(e) => onPwChanged(e.target.value)}
              />
            </div>
            <button className='login-btn' type='submit'>
              로그인
            </button>
          </form>
        </div>
        <div className='login-setting'>
          <CheckBox isChecked={checked} handleChecked={handleChecked} />
        </div>
      </div>
      <div className='login-bottom'>
        <div className='corporation-logo'>
          <img className='corporation-logo-img' src={images.corlogo} />
        </div>
      </div>
    </div>
  );
};

export default Login;
