import GNBContainer from '@components/common/GNB/containers/GNBContainer';
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import HomeContainer from '@components/Home/containers/HomeContainer';
import ListContainer from '@components/List/containers/ListContainer';
import SettingContainer from '@components/Setting/containers/SettingContainer';
import CFooterContainer from '@components/common/CFooter/containers/CFooterContainer';
import mainNavigationEndpoints from '@routes/types/MainNavigationEndpoints';

const MainNavigation = () => {
  return (
    <div className='main-navitaion-root'>
      <GNBContainer />
      <Routes>
        <Route
          path={mainNavigationEndpoints.home}
          element={<HomeContainer />}
        />
        <Route
          path={mainNavigationEndpoints.list}
          element={<ListContainer />}
        />
        <Route
          path={mainNavigationEndpoints.setting}
          element={<SettingContainer />}
        />
        <Route path='*' element={<Navigate to={'home'} />} />
      </Routes>
      <CFooterContainer />
    </div>
  );
};

export default MainNavigation;
