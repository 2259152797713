import React from 'react';
import '@styles/components/Login/checkbox.styles.css';
import images from '../assets/images';

type Props = {
  isChecked: boolean;
  handleChecked: () => void;
};

const CheckBox = ({ isChecked, handleChecked }: Props) => {
  return (
    <label className='check-box-root'>
      <input className='check-input' type='checkbox' onClick={handleChecked} />
      <div className='custom-checkbox-box'>
        {isChecked ? (
          <img src={images.select} alt='select' />
        ) : (
          <img src={images.unselect} alt='unselect' />
        )}
      </div>
      <p className='auto-login'>자동 로그인</p>
    </label>
  );
};

export default CheckBox;
